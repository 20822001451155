export enum LanguageEnum {
  en = 1,
  fr = 2,
  es = 3,
  de = 4,
}

export enum Language {
  English = 1,
  French = 2,
  Spanish = 3,
  German = 4,
}
